//require("expose?$!jquery");

(() => {
	var $popups = $('#nav-main .popup');

	$('#nav-main .trigger-mobile.t_menu').click( e => {
		e.preventDefault();
		$('.top-bar').toggleClass('active');
	});

	if (
		"IntersectionObserver" in window &&
		"IntersectionObserverEntry" in window &&
		"intersectionRatio" in window.IntersectionObserverEntry.prototype
	) {

		let observer = new IntersectionObserver(entries => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					console.log('intersecting', entry);
				}
			}
		});
		$('#content a[name]').each((index,el) => {
			observer.observe($(el).get(0));
		});

	}

	$('.hamburger').on('click', function () {
		$('nav.top').toggleClass('visible');
	});
})();

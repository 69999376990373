import '../scss/index.scss';

import jQuery from 'jquery';
import SliderPro from 'slider-pro'
import Utils from './utils';
// import Swiper from 'swiper/dist/js/swiper.min.js';
import screenDetector from './screen-detector'; // initializes screensize etc.

const urlParams = Utils.getQueryStringParams(window.location.search);
const login = urlParams.login;

import './nav.js';
import formValidator from './form-validator.js';
import modals from './modals.js';

// Slider / Carousel
import './hero-slider.js';

import './items';
import './jquery.form-validation';

import './ceo';

$(document).ready(() => {
    modals.initModals();
});


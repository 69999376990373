import modals from './modals.js';

$(document).ready(() => {
    $("[data-trigger='ceo-details']").on('click', (event) => {

        const href = (event && event.currentTarget ? event.currentTarget.href : false);

        if (href) {
            $.ajax(href, {
                method: 'GET',
                dataType: 'html',
                success: (data, textStatus, jqXHR) => {
                    $("[data-modal='ceo-details'] .modal-contents").html(data);
                    modals.show('ceo-details');
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    console.error("Could not perform request:\n", textStatus + "\n", errorThrown);
                },

            });
            return false;
        }

        return true;
    });
});
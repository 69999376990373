/**
 * Validate form (incomplete HTML5 polyfill)
 * 		only check for required fields and valid e-mailaddresses
 */


/**
 * Check if given e-mailaddress is valid
 * @Argument string
 * @Return   boolean
 */

function isValidEmail (email) {

	var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+[a-zA-Z0-9]{2,4}$/; 
	return pattern.test(email);
	
}

/**
 * Mark given input as invalid
 * @Argument $(input,textarea)
 */

function markInvalid ($input) {

	$input.addClass('finputError');

	var $form = $input.parents('form');

	//show error message for this field.
	var $msg = $form.find('[data-error="' + $input.attr('name') + '"]');
	$msg.show();

}

/**
 * Reset invalid form fields
 * @Argument $(form)
 */

function resetInvalidFormFields ($form) {
	$form.find('.finputError').removeClass('finputError');
}

$(document).ready(function () {

	$('form').each(function () {

		var $thisForm = $(this);
		$thisForm.find('[data-error]').hide();

		$(this).find('input[type=submit], button[type=submit]').on('click', function () {

			var $inputRequired = $thisForm.find('input[required=required], textarea[required=required]');
			var $inputEmail = $thisForm.find('input[type=email]');
			var isValidSubmit = true;

			// Reset invalid form fields
			resetInvalidFormFields($thisForm);

			// Validate input of each required field
			$inputRequired.each(function (i) {
				if ($(this).val() === '') {
					isValidSubmit = false;
					markInvalid($(this));
				}
			});

			// Validate input of each e-mail field
			$inputEmail.each(function (i) {
				if (!isValidEmail($(this).val())) {
					isValidSubmit = false;
					markInvalid($(this));
				}
			});

			return isValidSubmit;
		});
	})
});
